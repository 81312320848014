export const emailInput = {
    label: "Email",
    name: "email",
    placeholder: "",
    type: "email",
    validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
    constraints: {minLength: 3, maxLength: 80},
    width: "col-12 col-lg-6",
    checkId: "email",
    validation: "matching_email",
}

export const firstNameInput = {
    label: "First Name",
    name: "firstName",
    placeholder: "",
    type: "text",
    validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
    constraints: {minLength: 2, maxLength: 19},
    width: "col-12 col-lg-6",
    validation: "required",
}

export const lastNameInput = {
    label: "Last Name",
    name: "lastName",
    placeholder: "",
    validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
    constraints: {minLength: 2, maxLength: 19},
    type: "text",
    width: "col-12 col-lg-6",
    validation: "required",
}

export const passwordInput = {
    label: "Password",
    name: "password",
    placeholder: "",
    validators: ["REQUIRED", "PASSWORD"],
    type: "password",
    width: "col-12 col-lg-6",
}

export const zipCodeInput = {
    DK:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "ZIPCODE"],
        constraints: {length: 4},
        maxlength: "4",
        type: "text",
        width: "col-6",
    },
    ES:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "ZIPCODE"],
        constraints: {length: 5},
        maxlength: "5",
        type: "text",
        width: "col-6",
    },
    IE: {
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH_DIGIT", "MAX_LENGTH_DIGIT"],
        constraints: {minLength: 7, maxLength: 8},
        maxlength: "10",
        type: "text",
        width: "col-6",
    },
    IT:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "ZIPCODE"],
        constraints: {length: 5},
        maxlength: "5",
        type: "text",
        width: "col-6",
    },
    SE:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "SWEDISH_ZIPCODE"],
        maxlength: "6",
        type: "text",
        width: "col-6",
    },
    DE:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "ZIPCODE"],
        constraints: {length: 5},
        maxlength: "5",
        type: "text",
        width: "col-6",
    },
    AT:{
        label: "Zip Code",
        name: "zipCode",
        placeholder: "",
        validators: ["REQUIRED", "ZIPCODE"],
        constraints: {length: 4},
        maxlength: "4",
        type: "text",
        width: "col-6",
    },
}

export const cityInput = {
    label: "City",
    name: "city",
    placeholder: "",
    validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
    constraints: {minLength: 1, maxLength: 35},
    type: "text",
    width: "col-6",
}

export const streetInput = {
    label: "Street",
    name: "street",
    placeholder: "",
    type: "text",
    validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
    constraints: {minLength: 1, maxLength: 30},
    width: "col",
}

export const phoneCountryInput = {
    DK: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+45",
    },
    ES: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+34",
    },
    IE: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+353",
    },
    IT: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+39",
    },
    SE: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+46",
    },
    DE: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+49",
    },
    AT: {
        label: "",
        name: "phoneNumberCountry",
        placeholder: "",
        type: "text",
        validators: ["REQUIRED", "PHONE_PREFIX"],
        maxlength: "4",
        width: "col-2",
        initalValue: "+43",
    },
}

export const phoneInput = {
    DK: {
        label: "Telefonnummer",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
    ES: {
        label: "Número de teléfono",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
    IE: {
        label: "Phone Number",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:4
    },
    IT: {
        label: "Numero di telefono",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
    SE: {
        label: "Telefonnummer",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
    DE: {
        label: "Telefonnummer",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
    AT: {
        label: "Telefonnummer",
        name: "phoneNumber",
        placeholder: "",
        validators: ["REQUIRED","MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 9, maxLength: 17},
        filter: /^[0-9\b]+$/,
        type: "phone",
        width: "col-12",
        offset:3
    },
}

export const taxCodeTypeInput = {
    label: "Tax Code Type",
    name: "taxCodeType",
    type: "select",
    width: "col",
    emptyOption: "Select tax code type",
    options: [],
};

export const taxCodeInput = {
    ES: {
        label: "Tax Code",
        name: "taxCode",
        validators: ["EXACT_TAX_CODE_LENGTH"],
        constraints: { exactlyLength: 9 },
        maxLength: 9,
        placeholder: "",
        type: "text",
        width: "col",
    },
    IT: {
        label: "Tax Code",
        name: "taxCode",
        validators: ["EXACT_TAX_CODE_LENGTH"],
        constraints: { exactlyLength: 16 },
        maxLength: 16,
        placeholder: "",
        type: "text",
        width: "col",
    }
}

export const installationDateInput = {
    label: "INSTALLATION DATE",
    name: "installationDate",
    placeholder: "yyyy-mm-dd",
    validators: ["DATE"],
    type: "date",
    width: "col",
}

export const deviceTypeInput = {
    label: "MODEL NAME",
    name: "model",
    placeholder: "ADC05JEB5",
    type: "select",
    validators: ["REQUIRED"],
    width: "col",
    emptyOption: "-- Choose Model --",
    options: [
        {value: "I don't know", label: "I don't know"},
        {value: "WH-MDC05H3E5", label: "WH-MDC05H3E5"},
        {value: "WH-MDC07H3E5", label: "WH-MDC07H3E5"},
        {value: "WH-MDC07H3E5E", label: "WH-MDC07H3E5E"},
        {value: "WH-MDC09G3E5", label: "WH-MDC09G3E5"},
        {value: "WH-MDC09H3E5", label: "WH-MDC09H3E5"},
        {value: "WH-MDC09H3E5E", label: "WH-MDC09H3E5E"},
        {value: "WH-MDC12H6E5E", label: "WH-MDC12H6E5E"},
        {value: "WH-MDC16H6E5E", label: "WH-MDC16H6E5E"},
        {value: "WH-MHF12G9E8", label: "WH-MHF12G9E8"},
        {value: "WH-MXC09G3E5", label: "WH-MXC09G3E5"},
        {value: "WH-MXC09H3E5", label: "WH-MXC09H3E5"},
        {value: "WH-MXC09H3E5E", label: "WH-MXC09H3E5E"},
        {value: "WH-MXC12G6E5", label: "WH-MXC12G6E5"},
        {value: "WH-MXC12H6E5E", label: "WH-MXC12H6E5E"},
        {value: "WH-UD03HE5-1", label: "WH-UD03HE5-1"},
        {value: "WH-UD05HE5-1", label: "WH-UD05HE5-1"},
        {value: "WH-UD05HE5-1E", label: "WH-UD05HE5-1E"},
        {value: "WH-UD07HE5-1", label: "WH-UD07HE5-1"},
        {value: "WH-UD07HE5-1E", label: "WH-UD07HE5-1E"},
        {value: "WH-UD09HE5-1", label: "WH-UD09HE5-1"},
        {value: "WH-UD09JE5", label: "WH-UD09JE5"},
        {value: "WH-UD16HE5E", label: "WH-UD16HE5E"},
        {value: "WH-UD16HE8E", label: "WH-UD16HE8E"},
        {value: "WH-UH09FE5", label: "WH-UH09FE5"},
        {value: "WH-UX09HE5E", label: "WH-UX09HE5E"},
        {value: "WH-MDC05J3E5HE", label: "WH-MDC05J3E5HE"},
        {value: "WH-MDC16H6E5HE", label: "WH-MDC16H6E5HE"},
        {value: "WH-MHF12G6E5", label: "WH-MHF12G6E5"},
        {value: "WH-MXC09H3E5HE", label: "WH-MXC09H3E5HE"},
        {value: "WH-MXC09H3E8", label: "WH-MXC09H3E8"},
        {value: "WH-MXC09J3E5HE", label: "WH-MXC09J3E5HE"},
        {value: "WH-MXC12H6E5", label: "WH-MXC12H6E5"},
        {value: "WH-MXC12H6E5HE", label: "WH-MXC12H6E5HE"},
        {value: "WH-MXC12H9E8", label: "WH-MXC12H9E8"},
        {value: "WH-MXC16H9E8", label: "WH-MXC16H9E8"},
        {value: "WH-UD03JE5", label: "WH-UD03JE5"},
        {value: "WH-UD05JE5HE", label: "WH-UD05JE5HE"},
        {value: "WH-UD09JE5-1HE", label: "WH-UD09JE5-1HE"},
        {value: "WH-UD12HE5HE", label: "WH-UD12HE5HE"},
        {value: "WH-UH12FE8", label: "WH-UH12FE8"},
        {value: "WH-UQ12HE8", label: "WH-UQ12HE8"},
        {value: "WH-UQ16HE8", label: "WH-UQ16HE8"},
        {value: "WH-UX09HE5", label: "WH-UX09HE5"},
        {value: "WH-UX09HE5HE", label: "WH-UX09HE5HE"},
        {value: "WH-UX12HE5", label: "WH-UX12HE5"},
        {value: "WH-MDC09J3E5HE", label: "WH-MDC09J3E5HE"},
        {value: "WH-MDC12H6E5HE", label: "WH-MDC12H6E5HE"},
        {value: "WH-MHF09G3E5", label: "WH-MHF09G3E5"},
        {value: "WH-MXC09J3E5", label: "WH-MXC09J3E5"},
        {value: "WH-MXC12J6E5HE", label: "WH-MXC12J6E5HE"},
        {value: "WH-UD07JE5HE", label: "WH-UD07JE5HE"},
        {value: "WH-UH12FE5", label: "WH-UH12FE5"},
        {value: "WH-UQ09HE8", label: "WH-UQ09HE8"},
        {value: "WH-UX12HE5HE", label: "WH-UX12HE5HE"},
        {value: "WH-MDC07J3E5HE", label: "WH-MDC07J3E5HE"},
        {value: "WH-MDC09J3E5E", label: "WH-MDC09J3E5E"},
        {value: "WH-MHF12G6E5E", label: "WH-MHF12G6E5E"},
        {value: "WH-UD07JE5E", label: "WH-UD07JE5E"},
        {value: "WH-UDZ03KE5", label: "WH-UDZ03KE5"},
        {value: "WH-UX09HE8", label: "WH-UX09HE8"},
        {value: "WH-UXZ12KE5", label: "WH-UXZ12KE5"},
        {value: "WH-MDC07J3E5E", label: "WH-MDC07J3E5E"},
        {value: "WH-MDC12H6E5", label: "WH-MDC12H6E5"},
        {value: "WH-MXC09J3E5-1", label: "WH-MXC09J3E5-1"},
        {value: "WH-MXC09J3E8", label: "WH-MXC09J3E8"},
        {value: "WH-MXC12J9E8", label: "WH-MXC12J9E8"},
        {value: "WH-MXC16J9E8", label: "WH-MXC16J9E8"},
        {value: "WH-MXC16J9E8-1", label: "WH-MXC16J9E8-1"},
        {value: "WH-MXC16J9E8E", label: "WH-MXC16J9E8E"},
        {value: "WH-UD05JE5", label: "WH-UD05JE5"},
        {value: "WH-UD07JE5", label: "WH-UD07JE5"},
        {value: "WH-UD09HE8", label: "WH-UD09HE8"},
        {value: "WH-UD09JE5-1", label: "WH-UD09JE5-1"},
        {value: "WH-UD12HE5", label: "WH-UD12HE5"},
        {value: "WH-UD12HE5E", label: "WH-UD12HE5E"},
        {value: "WH-UD12HE8", label: "WH-UD12HE8"},
        {value: "WH-UD16HE5", label: "WH-UD16HE5"},
        {value: "WH-UD16HE8", label: "WH-UD16HE8"},
        {value: "WH-UDZ07KE5E", label: "WH-UDZ07KE5E"},
        {value: "WH-UDZ09KE8", label: "WH-UDZ09KE8"},
        {value: "WH-UDZ12KE8", label: "WH-UDZ12KE8"},
        {value: "WH-UX12HE8", label: "WH-UX12HE8"},
        {value: "WH-UX16HE8", label: "WH-UX16HE8"},
        {value: "WH-UXZ12KE5HE", label: "WH-UXZ12KE5HE"},
        {value: "WH-UXZ12KE8", label: "WH-UXZ12KE8"},
        {value: "WH-WXG09ME8", label: "WH-WXG09ME8"},
        {value: "WH-WXG25ME8", label: "WH-WXG25ME8"},
        {value: "WH-MDC05J3E5", label: "WH-MDC05J3E5"},
        {value: "WH-MDC07J3E5", label: "WH-MDC07J3E5"},
        {value: "WH-MDC09J3E5", label: "WH-MDC09J3E5"},
        {value: "WH-MDC16H6E5", label: "WH-MDC16H6E5"},
        {value: "WH-MXC12J6E5", label: "WH-MXC12J6E5"},
        {value: "WH-MXC12J9E8-1", label: "WH-MXC12J9E8-1"},
        {value: "WH-UDZ05KE5", label: "WH-UDZ05KE5"},
        {value: "WH-UDZ07KE5", label: "WH-UDZ07KE5"},
        {value: "WH-UDZ09KE5", label: "WH-UDZ09KE5"},
        {value: "WH-UDZ12KE5", label: "WH-UDZ12KE5"},
        {value: "WH-UDZ16KE8", label: "WH-UDZ16KE8"},
        {value: "WH-UXZ09KE5", label: "WH-UXZ09KE5"},
        {value: "WH-UXZ09KE8", label: "WH-UXZ09KE8"},
        {value: "WH-UXZ16KE8", label: "WH-UXZ16KE8"},
        {value: "WH-WDG05LE5", label: "WH-WDG05LE5"},
        {value: "WH-WDG07LE5", label: "WH-WDG07LE5"},
        {value: "WH-WDG09LE5", label: "WH-WDG09LE5"},
        {value: "WH-WXG09ME5", label: "WH-WXG09ME5"},
        {value: "WH-WXG12ME5", label: "WH-WXG12ME5"},
        {value: "WH-WXG12ME8", label: "WH-WXG12ME8"},
        {value: "WH-WXG16ME8", label: "WH-WXG16ME8"},
        {value: "WH-WXG20ME8", label: "WH-WXG20ME8"},
        {value: "WH-WXG30ME8", label: "WH-WXG30ME8"}
    ]
};

export const serialNumberInput = {
    label: "SERIAL NUMBER",
    name: "serialNumber",
    validators: ["REQUIRED", "DIGITS_ONLY","MIN_LENGTH", "MAX_LENGTH"],
    constraints: {minLength: 8, maxLength: 20},
    maxlength: "20",
    filter: /^[0-9\b]+$/,
    placeholder: "",
    type: "text",
    width: "col",
}

export const gateWayIdInput = {
    label: "DEVICE ID",
    name: "gatewayId",
    validators: ["EXACTLY_MATCH_GATEWAYID_FORMAT"],
    placeholder: "B2343-34222",
    type: "text",
    width: "col",
    hint: "* mandatory for the contract types Smart and Premium"
}

export const internetReadyInput = {
    label: "Is the unit internet ready?",
    name: "internetReady",
    type: "radio",
    width: "col",
    options: [
        {value:"true", label:"YES"},
        {value:"false", label:"NO"},
    ],
    images: [
        "/image/checkout/NewController.jpg",
         "/image/checkout/OldController.jpg",
    ],
}
export const internetConnectedInput = {
    label: "Is the cloud adapter available?",
    name: "internetConnected",
    type: "radio",
    width: "col",
    options: [
        {value:"true", label:"YES"},
        {value:"false", label:"NO"},
    ],
    images: [
        "/image/checkout/CloudAdapter.jpg"
    ],
}
export const sendInvoice = {
    ES:{
        label: "Send Invoice",
        name: "sendInvoice",
        type: "checkbox",
        width: "col",
    }
}