export const countryInput = {
    label: "Country",
    name: "country",
    placeholder: "Counntry",
    type: "select",
    validators: [],
    width: "col",
    emptyOption: "-- Choose Country --",
    options: [
        {value:"DK", label:"Denmark"},
        {value:"ES", label:"Spain"},
        {value:"IE", label:"Ireland"},
        {value:"IT", label:"Italy"},
        {value:"SE", label:"Sweden"},
        {value:"DE", label:"Germany"},
        {value:"AT", label:"Austria"},
    ]
};
export const filterMonthInput = {
    label: "Month",
    name: "month",
    placeholder: "Month",
    type: "select",
    validators: [],
    width: "col",
    emptyOption: "-- Select Month --",
    options: [
        {value:"01", label:"January"},
        {value:"02", label:"February"},
        {value:"03", label:"March"},
        {value:"04", label:"April"},
        {value:"05", label:"May"},
        {value:"06", label:"June"},
        {value:"07", label:"July"},
        {value:"08", label:"August"},
        {value:"09", label:"September"},
        {value:"10", label:"October"},
        {value:"11", label:"November"},
        {value:"12", label:"December"},
    ]
};
export function filterYearInput() {
    return {
        label: "year",
        name: "year",
        placeholder: "year",
        type: "select",
        validators: [],
        width: "col",
        emptyOption: "-- Select Year --",
        options: createDynamicallyYearOptions(2022)
    }

};
export const filterTypeInput = {
    label: "type",
    name: "type",
    placeholder: "type",
    type: "select",
    validators: [],
    width: "col",
    emptyOption: "-- Filter By --",
    options: [
        {value:"invitationDate", label:"Invitation Date"},
        {value:"purchaseDate", label:"Purchase Date"},
    ]
};
const getRangeOfNumbers = function(start, end) {
    var numbers = [];
    while ( start <= end ) {
        numbers.push(start++);  
    }
    return numbers;
};
const createDynamicallyYearOptions = function(beginningYear) {
    var years = getRangeOfNumbers(beginningYear, new Date().getFullYear());
    console.log("createDynamicallyYearOptions: years", years);
    var yearOptions = [];
    years.forEach(year => yearOptions.push({
        value: year.toString(),
        label: year.toString()
    }))
    console.log("createDynamicallyYearOptions: yearOptions", yearOptions);
    return yearOptions;
};