import React, {useState, useEffect} from 'react';
import classNames from "classnames";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import i18n from "../../lib/i18n";
import {getValidationMessage, getValidators, isOptional, showValidation} from "../util/validators";

const t = i18n.namespace("Validation");

export const FormSection = ({children, className}) => {
  return (
    <div className={classNames("form-section", className)}>
      {children}
    </div>
  );
}


export const FormContainer = ({children, className}) => {
  return (
    <div className={classNames("form-container", className)}>
      {children}
    </div>
  );
}

export const FormFieldset = ({children, className}) => {
  return (
    <fieldset className={classNames("form-fieldset", className)}>
      {children}
    </fieldset>
  );
}


export const FormHeadline = ({children, className, headline}) => {
  return (
    <h2 className={classNames("form-headline", className)}>
      {headline}{children}
    </h2>
  );
}

export const FormRow = ({children}) => {
  return (
    <div className="form-row">
      {children}
    </div>
  );
}

export const FormGroup = ({children, htmlFor, label, className, isOptional}) => {
  const labelText = isOptional ? label + ' (' + t("optionalValue") + ')' : label;
  return (
    <div className={classNames("form-group", className)}>
      {label && (htmlFor ? <label className="form-label" htmlFor={htmlFor} dangerouslySetInnerHTML={{ __html: labelText }}/> : <legend className="form-label">{labelText}</legend>)}
      {children}
    </div>
  );
}

export const InputGroup = ({children, prependIcon, appendText}) => {
  return (
    <div className="input-group mb-3">
      {prependIcon &&
       <div className="input-group-prepend">
        <span className="input-group-text input-group-text--icon">{prependIcon}</span>
       </div>
      }
      {children}
      {appendText &&
       <div className="input-group-append">
         <span className="input-group-text">{appendText}</span>
       </div>
      }
    </div>
  );
}

export const ValidationMessage = ({children, message}) => {
  return (
    <div className="invalid-feedback">
      {message}{children}
    </div>
  );
}

export const FormText = ({children, className}) => {
  return (
    <p className={classNames("form-text", className)}>
      {children}
    </p>
  );
}

export const FormInfoBox = ({children, className}) => {
  return (
    <div className={classNames("form-info-box", className)}>
      {children}
    </div>
  );
}

export const FormInfoBoxHeadline = ({children, className, headline}) => {
  return (
    <div className={classNames("form-info-box_headline", className)}>
      {headline}{children}
    </div>
  );
}

export const FormItem = (props) => {
  const item = props.item;
  const validators = getValidators(item.validators);
  const isOptionalFormItem = isOptional(item.validators);
  const type = item.type;
  const constraints = item.constraints ?? {};
  const showSomeMessages = props.showSomeMessages;
  const validationMessages = item.validationMessages;
  const country = props.country;
  const [state, setState] = useState({error: false, message: ""});
  const [controlValue, setControlValue] = useState(props.currentValue ?? '');
  const [touched, setTouched] = useState(false);
  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(() => {
      setControlValue(props.currentValue);
      setDisabled(props.disabled);

      onControlChange({
          target: {
              name: item.name,
              title: item.name,
              value: props.currentValue ?? '',
          }
      });

  }, [props.currentValue, props.disabled]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      if (showSomeMessages) {
          setTouched(true);
      }
  }, [showSomeMessages])

  const shouldShowValidation = () => showValidation(state.error, touched, !!props.backendValidationError);
  const validate = (value) => {
      const validationState = {
          invalid: false,
          errorKey: ''
      }

      if (validators.length === 0) {
          return validationState;
      }
      if(item.name === "phoneNumber"){
        value = value?.replace('+', '')
      }

      validationState.invalid = validators.some(validator => {
          const error = validator(value, constraints, props.currentStepValues);

          if (error) {
              validationState.errorKey = error;
          }

          return !!error;
      })

      return validationState;
  }

  const onControlChange = (event) => {
    if(item.filter && event.target.value && !item.filter.test(event.target.value)){
        return
    }

    let value = event.target.type === "checkbox" ? !!event.target.checked : event.target.value;


      /*
      if (!value) {
          value = props.currentValue;
      }
      */
      const {invalid, errorKey} = validate(value);

      setControlValue(value);
      //console.debug(`Control: ${event.target.name} is invalid: ${invalid} and has Error: ${errorKey}`, event.target.value);
      setState({...state, error: invalid, message: errorKey});
      props.saveValue(event, invalid);
  }

  const onControlBlur = event => {
      setTouched(true);
      onControlChange(event);
  }
  const onPhoneChange = (val, data, event, formattedValue) => {
    const {invalid, errorKey} = validate(formattedValue);
    setControlValue(formattedValue);
    setState({...state, error: invalid, message: errorKey});
    props.saveValue(event, invalid);
}

  const renderInputsByType = (type) => {
      switch (type) {
          case "text":
              return (
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width}
                             isOptional={isOptionalFormItem}>
                      {item.hint && <p className="hint">{item.hint}</p>}
                      <input
                          className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                          type={type}
                          placeholder={item.placeholder}
                          id={item.name}
                          name={item.name}
                          disabled={disabled}
                          maxLength={item.maxLength}
                          value={controlValue}
                          onBlur={event => onControlBlur(event)}
                          onChange={event => onControlChange(event)}
                      />
                      <ValidationMessage
                          message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                  </FormGroup>
              );
          case "email":
              return (
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                      <input
                          className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                          type={type}
                          placeholder={item.placeholder}
                          id={item.name}
                          name={item.name}
                          disabled={disabled}
                          value={controlValue}
                          onBlur={event => onControlBlur(event)}
                          onChange={event => onControlChange(event)}
                      />
                      <ValidationMessage
                          message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                  </FormGroup>
              );
          case "select":
              return (
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width} isOptional={isOptionalFormItem}>
                      <select className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                              name={item.name}
                              id={item.name}
                              disabled={disabled || item.options.length === 0}
                              value={controlValue}
                              onBlur={event => onControlBlur(event)}
                              onChange={event => onControlChange(event)}>
                          {item.emptyOption && <option value="">{item.emptyOption}</option>}
                            {
                                item.options.map((option, i) =>
                                    <option key={i} value={option.value}>{option.label}</option>
                                )
                            }
                      </select>
                      <ValidationMessage
                          message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                  </FormGroup>
              );
          case "radio-button-list":
              return(
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                          {item.options.map((option, i) => {
                            console.log(option,"option")
                              const groupClassName = option.group &&  option.group % 2 ? "form-check-option-uneven"  : "form-check-option-even";
                              return <div key={option.label} className={"form-check "+ groupClassName}>
                                <input className={"form-check-input"}
                                  id={option.label + i}
                                  type={"radio"}
                                  name={item.name}
                                  value={option.value}
                                  onChange={(event) => onControlChange(event)}/>
                                  <label className={"form-check-label"} htmlFor={option.label + i}>
                                      {option.label}
                                  </label>
                              </div>})
                          }
                  </FormGroup>
              );
          case "password":
              return (
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                      <input
                          className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                          type={type}
                          placeholder={item.placeholder}
                          id={item.name}
                          name={item.name}
                          disabled={disabled}
                          value={controlValue}
                          onBlur={event => onControlBlur(event)}
                          onChange={event => onControlChange(event)}
                      />
                      <ValidationMessage
                          message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                  </FormGroup>
              );
          case "date":
              return (
                  <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                      <InputGroup prependIcon={<img src="/image/icons/datepicker.svg" alt=""/>}>
                          <input
                              id={item.name}
                              className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                              type={type}
                              placeholder={item.placeholder}
                              min="01.01.2000"
                              max={new Date().toJSON().split('T')[0]}
                              name={item.name}
                              disabled={disabled}
                              value={controlValue}
                              // onFocus={(event => _onFocus(event))}
                              onBlur={e => onControlBlur(e)}
                              onChange={event => onControlChange(event)}
                          />
                          <ValidationMessage
                              message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                      </InputGroup>
                  </FormGroup>
              )
              case "checkbox":
                return (
                    <FormGroup>
                        <InputGroup>
                            <input
                              className="form-check-input"
                              id={item.name}
                              type="checkbox"
                              value={controlValue}
                              name={item.name}
                              onChange={event => onControlChange(event)}
                            />
                            <label className="form-check-label" htmlFor={item.name}>
                              {item.label}
                            </label>
                        </InputGroup>
                    </FormGroup>
                )
          case "phone":
            return (
                <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                    <PhoneInput
                        className={classNames({"is-invalid": shouldShowValidation()})}
                        inputStyle={{color: "steelblue", height: "40px", width: "100%"}}
                        containerStyle={{height: "40px"}}
                        inputProps={{
                            name: 'phoneNumber',
                            required: true,
                        }}
                        country={country.toLowerCase()}
                        onlyCountries={["dk", "es", "ie", "it", "se", "de", "at"]}
                        value={controlValue}
                        onBlur={e => onControlBlur(e)}
                        onChange={onPhoneChange}
                        enableLongNumbers={18}
                        autoFormat={false}
                        placeholder=''
                    />
                    <ValidationMessage
                            message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                </FormGroup>
            );
          default:
              return <div>{type}</div>
      }
  }

  return (
      <>{renderInputsByType(type)}</>
  )
};
